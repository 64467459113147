
<div class="position-relative w-100">
  <input type="text" placeholder="Buscar..." v-model="search_input" @keyup.enter="search">
  <button @click="search" aria-label="Buscador">
    <svg id="Layer_2" style="width: 20px;height:20px;fill:white" data-name="Layer 2"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.74 377.65">
      <g id="Layer_5" data-name="Layer 5">
        <path
            d="m229.79,254.76s-.01-.01-.02-.02c-1.11-1.1-2.86-1.24-4.15-.35-3.21,2.21-6.36,4.42-9.62,6.47-84.86,53.18-196.83,3.09-213.89-95.67-.82-4.73-1.42-9.49-2.12-14.24.02-6.72.04-13.33.06-20.06.87-5.73,1.58-11.49,2.71-17.17C14.41,55.54,61.24,10.66,119.59,1.66c74.4-11.48,145.39,37.86,159.93,111.77,7.96,40.46-.48,77.74-24.61,111.33-.07.1-.14.2-.21.3-.94,1.31-.8,3.1.34,4.24,35.24,35.24,99.27,101.83,119.95,123.38,3.7,3.85,3.67,9.94-.06,13.76-2.89,2.95-6.1,6.23-8.27,8.46-3.47,3.55-9.13,3.7-12.79.33-21.48-19.79-88.68-85.05-124.08-120.46Zm17.3-113.48c0-58.46-47.37-105.96-105.77-106.07-58.41-.11-106.25,47.74-106.17,106.16.09,58.36,47.63,105.77,106.07,105.78,58.45.01,105.87-47.41,105.87-105.87Z"/>
      </g>
    </svg>
  </button>

  <div id="result-products" class="search-result bg-white" v-if="payload">
    <template v-if="payload.products && payload.products.data && payload.products.data.length > 0 || payload.brands && payload.brands.data && payload.brands.data.length > 0">
      <div id="ignore" class="mt-3 w-100 text-center bg-white" v-if="payload.products && payload.products.data && payload.products.data.length > 0">
        <h4 id="ignore" class="bg-white">Productos</h4>
        <div id="ignore" class="navb-box w-80" v-for="(product, index) in payload.products.data" :key="index">
          <img id="ignore" class="navb-img" v-if="product.asset" :src="`/api/public/v1/assets/${product.asset.slug}`">
          <div id="ignore" class="navb-content w-100">
            <h3 id="ignore" class="navb-h3">{{product.name}}</h3>
            <label id="ignore" class="navb-quantity navb-description">{{product.short_description}}</label><br>
            <a id="ignore" class="btn btn-primary show-more w-100" :href="`/productos/${product.slug}`">Ver producto</a>
          </div>
        </div>
      </div>

      <div id="ignore" class="w-100 text-center bg-white" v-if="payload.brands && payload.brands.data && payload.brands.data.length > 0">
        <h4 id="ignore" class="bg-white">Marcas</h4>
        <div id="ignore" class="navb-box w-80" v-for="(brand, index) in payload.brands.data" :key="index">
          <img id="ignore" class="navb-img" v-if="brand.asset" :src="`/api/public/v1/assets/${brand.asset.slug}`">
          <div id="ignore" class="navb-content w-100">
            <h3 id="ignore" class="navb-h3" >{{brand.name}}</h3>
            <a id="ignore" class="btn btn-primary show-more w-100" :href="`/marcas/${brand.slug}`">Ver marca</a>
          </div>
        </div>
      </div>
    </template>

    <div id="ignore" class="w-100 text-center bg-white" v-else>
      <h4 id="ignore" class="bg-white p-4">No se han encontrado resultados con esa búsqueda.</h4>
    </div>
  </div>
</div>
