import Vue from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import VueAWN from "vue-awesome-notifications";
import 'vue-awesome-notifications/dist/styles/style.css';
Vue.use(VueAWN, {
    position: "top-right",
});
Vue.prototype.$contact = contact ? contact : null;
// Global event bus, just in case it's needed
Vue.prototype.$eventHub = new Vue();


document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(Login)
    }).$mount('#app_buttons_1')
})

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(Login)
    }).$mount('#app_buttons_2')
})

document.addEventListener('DOMContentLoaded', () => {
    const app = new Vue({
        render: h => h(App)
    }).$mount('#app_search')
})